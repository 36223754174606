import React, { useEffect, useState } from "react";
import styles from "./AddHeadlineDialog.module.scss";
import _ from "lodash";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import useForm from "../../hooks/useForm";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useMediaQuery, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Loading from "../Loading/Loading";
import { MEETING_FIELDS } from "../../utils/fragments";
import SelectDepartment from "../SelectDepartment/SelectDepartment";

import { HEADLINE_CATEGORIES } from "../../utils/const";
import { DatePicker } from "@material-ui/pickers";
import { isDate } from "date-fns";
import { addToDate } from "../../utils/dates";

const AddHeadlineDialog = ({ dialog, setDialog, params, snack, user }) => {
  const [createHeadline, { loading }] = useMutation(CREATE_HEADLINE);
  const [addHeadlineToMeeting, { loading: loadingHeadlineToMeeting }] = useMutation(ADD_HEADLINE_TO_MEETING);

  const [meeting, setMeeting] = useState({});
  const [headlineSteps,setHeadlineSteps] = useState([]);

  const fs = useMediaQuery("(max-width: 600px)");

  const defaultType = Object.values(HEADLINE_CATEGORIES).find(category => category.default)?.value || HEADLINE_CATEGORIES['general'].value;

  const initForm = {
    organization: params.org,
    category: defaultType,
    value: null,
    summary: "",
    author: user.user.id,
    displayUntilDate: addToDate(new Date(), { days: 7 }),
    sharedPlanIds: [],    
    meeting: null,
  };
  const initErrorForm = {
    value: ["required"],
    category: ["required"],
    //sharedPlanId: ["required"],
  };

  const { form, formErrors, handleChange, handleChangeDate, resetForm, validateForm, handleChangeManual } = useForm({
    initForm,
    initErrorForm,
  });

  const [getDepartments, { data: plansData, loading:plansLoading, refetch: plansRefetch }] = useLazyQuery(GET_DEPARTMENTS);
  
  const getHeadlineStepIndices = () => {
    let stepIndex = null;
    let contentIndex = null;
    if (headlineSteps.length) {
      const firstHeadlineStep = headlineSteps[0];

      const rawStepIndex = _.findIndex(meeting.steps, (step) => step === firstHeadlineStep);
      stepIndex = rawStepIndex === -1 ? null : rawStepIndex;

      if (stepIndex) {
          const rawContentIndex = _.findIndex(firstHeadlineStep.content, (content) => content.type === 'headlineReview');
          contentIndex = rawContentIndex === -1 ? null : rawContentIndex;
      }
    } 
    
    return {stepIndex, contentIndex};
  }
  const handleSubmit = (close) => async () => {
    if (!validateForm()) return;

    const { organization, category, value, summary, author, displayUntilDate, sharedPlanIds } = form;
    const startOfDayDisplayUntilDate = new Date(displayUntilDate);
    startOfDayDisplayUntilDate.setHours(0, 0, 0, 0);
    const { data } = await createHeadline({
      variables: { organization, category, value, summary, author, displayUntilDate: startOfDayDisplayUntilDate, sharedPlanIds },
    });

    if (data.createHeadline) {
      const {stepIndex, contentIndex } = getHeadlineStepIndices();
      if ( data.createHeadline.id && meeting.id && stepIndex !== null) {
        const { data: dataUpdatedMeeting } = await addHeadlineToMeeting({
          variables: { id: meeting.id, headlineId: data.createHeadline.id, stepIndex: stepIndex, contentIndex: contentIndex},
        });

        if (_.get(dataUpdatedMeeting,'addHeadlineToMeeting',null)) {
          snack("Added headline to meeting");
        } 
        else {
          snack("Created new headline");
        }
      } 
      else {
        snack("Created new headline");
      }
 
      if (close) {
        handleClose();
      }
      else {
        const newForm = _.cloneDeep(form);
        _.set(newForm, "value", null);
        _.set(newForm, "summary", "");
        resetForm(newForm);
      }
    } 
    else {
      snack("Could not create the new headline", "error");
    }
  };

  const handleClose = () => {
    resetForm();
    setDialog({ ...dialog, addHeadlineDialog: { open: false } });
  };

    useEffect(() => {
      if (plansData) { 
        const plans = _.get(plansData, "plans", []);
        const isValid = form.sharedPlanIds.some(id => plans.some(plan => plan.sharedPlanId === id));
      
        if (!isValid) {
          handleChangeManual({ name: "sharedPlanIds", value: [] });
        }
      }
    }, [plansData]);

  useEffect(() => {
    if (_.get(dialog, "addHeadlineDialog.open", false)) {
      const existingMeeting = _.get(dialog, "addHeadlineDialog.meeting", {});
      setMeeting(existingMeeting);
      if (existingMeeting?.sharedPlanId) {
        handleChangeManual({ name: "sharedPlanIds", value: [existingMeeting?.sharedPlanId] });
      }
      const filterWithCorpPlan = _.get(dialog, "addHeadlineDialog.corpPlanId", null);
      getDepartments({ variables: { organization: params.org, oneYearCorpPlan: filterWithCorpPlan } });        
    }
  }, [dialog.addHeadlineDialog]);


   useEffect(() => {
    const currentHeadlineSteps = _.get(meeting, "steps", []).filter((step) =>
      _.get(step, "content", []).some((content) => content.type === 'headlineReview') || step.value === 'headlines'
    );
    setHeadlineSteps(!_.isEmpty(currentHeadlineSteps) ? currentHeadlineSteps : []);

    const {stepIndex, contentIndex } = getHeadlineStepIndices();

  }, [meeting]);
  

  return (
    <Dialog
      open={Boolean(dialog.addHeadlineDialog.open)}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
      fullScreen={fs}
    >
      <DialogTitle>
        <div className={styles.title}>
          {headlineSteps.length ? 'Add Headline to Meeting' : 'Create Headline'}
          <div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>

      <DialogContent>
      <TextField
            label="Enter a Headline"
            name="value"
            fullWidth
            variant="outlined"
            margin="normal"
            value={form.value || ""}
            onChange={handleChange}
            helperText={formErrors.value}
            error={Boolean(formErrors.value)}
          />
          <TextField
            label="Description or Instructions (Optional)"
            name="summary"
            fullWidth
            variant="outlined"
            margin="normal"
            value={form.summary || ""}
            onChange={handleChange}
            helperText={formErrors.summary}
            error={Boolean(formErrors.summary)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel variant="outlined">Category</InputLabel>          
            <Select
              label="Category"
              name="category"
              value={form.category || ""}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
              error={Boolean(formErrors.category)}
            >
              {Object.values(HEADLINE_CATEGORIES).map((category) => (
                <MenuItem key={category.value} value={category.value}>
                  {category.name}
                </MenuItem>
              ))}              
            </Select>
          </FormControl>
          <DatePicker
            autoOk
            clearable
            value={form.displayUntilDate}
            onChange={handleChangeDate("displayUntilDate")}
            fullWidth
            inputVariant="outlined"
            format="dd/MM/yyyy"
            margin="normal"
            label="Display Until Date"     
          />   
          <SelectDepartment
            plans={ _.uniqBy(_.get(plansData, "plans"), 'sharedPlanId') }
            name="sharedPlanIds"
            handleChange={handleChange}
            value={form.sharedPlanIds}
            helperText={formErrors.sharedPlanIds}
            error={Boolean(formErrors.sharedPlanIds)}
            selectType="sharedPlanId"
            multiple
            showAll={false}
          />                 
      </DialogContent>
      <DialogActions>
      <Button onClick={handleSubmit(false)} color="primary" variant="outlined" disabled={loading} className={styles.button}>
          {loading ? <Loading size={24} color="#fff" /> : "Create & Add Another"}
        </Button>        
        <Button onClick={handleSubmit(true)} color="primary" variant="contained" disabled={loading} className={styles.button}>
          {loading ? <Loading size={24} color="#fff" /> : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddHeadlineDialog;



const CREATE_HEADLINE = gql`
  mutation Headlines_CreateHeadline(
    $organization: ID
    $category: String!
    $value: String!
    $summary: String
    $author: ID
    $displayUntilDate: String!
    $sharedPlanIds: [ID]
  ) {
    createHeadline(
      organization: $organization
      category: $category
      value: $value
      summary: $summary
      author: $author
      displayUntilDate: $displayUntilDate
      sharedPlanIds: $sharedPlanIds
    ) {
      id
      organization
      category
      value
      summary
      author {
        id
        name {
          first
          last
        }
      }
      displayUntilDate
      sharedPlanIds
    }
  }
`;   

const ADD_HEADLINE_TO_MEETING = gql`
${MEETING_FIELDS}
  mutation AddHeadlineDialog_addHeadlineToMeeting($id: ID!, $headlineId: ID!, $stepIndex: Int!, $contentIndex: Int) {
    addHeadlineToMeeting(id: $id, headlineId: $headlineId, stepIndex: $stepIndex, contentIndex: $contentIndex) {
      ...MeetingFields
    }
  }
`;

const GET_DEPARTMENTS = gql`
  query AddHeadlineDialog_GetDepts($organization: ID!, $oneYearCorpPlan: ID) {

    plans(organization: $organization, oneYearCorpPlan: $oneYearCorpPlan, category: "1 year", closed: false) {
      id
      departmentName
      sharedPlanId
    }
  }
`;